/* eslint-disable */

<template>
  <div>
    <vue-sortable :options="options" @drag:start="startedDrag" @drag:stop="stoppedDrag" @sortable:sort="sort">
      <div class="vx-row" :class="{ 'cursor-drag': draggingCard === true }">
        <div class="vx-col lg:w-3/12 md:w-3/12 sm:w-5/5">
          <vx-card class="mb-base">
            <h3 class="font-bold mb-4">Types</h3>
            <vue-draggable-container id="wordBank" class="wordBank">
              <h5 class="dragBlock my-4 font-semibold cursor-pointer">Hashed Value</h5>
              <h5 class="dragBlock my-4 font-semibold cursor-pointer">Encrypted String</h5>
              <h5 class="dragBlock my-4 font-semibold cursor-pointer">Document Authenticity</h5>
              <h5 class="dragBlock my-4 font-semibold cursor-pointer">Public Key</h5>
            </vue-draggable-container>
          </vx-card>
        </div>
        <div class="vx-col lg:w-9/12 md:w-9/12 sm:w-5/5">
          <vx-card class="mb-base">
            <div class="fourth-sim pt-0">
              <div class="vx-row mb-4">
                <div class="vx-col w-6/12 flex items-center">
                  <h2 class="font-bold">Response</h2>
                </div>
                <div class="vx-col w-6/12 flex items-center">
                  <h2 class="font-bold">Type</h2>
                </div>
              </div>
              <div class="vx-row border-white border-2 border-solid rounded p-4 items-center my-4">
                <div class="vx-col w-6/12 flex">
                  <code class="font-semibold break-all p-2">
                    -----BEGIN PGP SIGNED MESSAGE----<br />
                    Test Message<br />
                    -----BEGIN PGP SIGNATURE-----<br />
                    Version vl.4.14<br />
                    iF4EAREIAAYFAlKSpacACgkQuJVtv+58EFxiXgD/QGwQVCZMAlE7fL6V1Kbv4OgqSa9VTEw+s9mWwiBinp4A/3guc2PvZb8iidrCyWGwIMJIOQS8OuhWfjtN3CBhOSA1<br />
                    -----END PGP SIGNATURE-----<br />
                  </code>
                </div>
                <div class="vx-col w-6/12">
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg" id="input0">
                  </vue-draggable-container>
                </div>
              </div>
              <div class="vx-row border-white border-2 border-solid rounded p-4 items-center my-4">
                <div class="vx-col w-6/12 flex">
                  <code class="font-semibold break-all p-2">
                    cat /etc/shadow I grep root<br />
                    root"$1$FD0Pk65$444xPG7p4F/Ti7cn.:15558:0:88888:7:::<br />
                  </code>
                </div>
                <div class="vx-col w-6/12">
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg" id="input1">
                  </vue-draggable-container>
                </div>
              </div>
              <div class="vx-row border-white border-2 border-solid rounded p-4 items-center my-2">
                <div class="vx-col w-6/12 flex">
                  <code class="font-semibold break-all p-2">
                    -----BEGIN CERTIFICATE----<br />
                    mQMuBFG3x4URCACZ/c7PjmPwOy2qiyKAYRftIT7YurxmZ/wQEwkyLJ4R+A2mFAvw
                    EfdVjghAKwnXxqeZO9WyAEofqIXSewXD9J4H6THaWN1DeNwnIUhbVsSEgT6iwGEG
                    arXvkrMyy+USKA0x2dcsYRKAPMM1db+4zSQkWTWzufLU7lcKi3gU3pNTxSA0DjCn
                    wfJQspiyWchSfgZ59+fKaGZJVSElrS2i2ok5mK3ywCXRWvnAC/VxA3N6T4jvkX/+<br />
                    -----END CERTIFICATE-----<br />
                  </code>
                </div>
                <div class="vx-col w-6/12">
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg" id="input2">
                  </vue-draggable-container>
                </div>
              </div>
              <div class="vx-row border-white border-2 border-solid rounded p-4 items-center my-2">
                <div class="vx-col w-6/12 flex">
                  <code class="font-semibold break-all p-2">
                    -----BEGIN PGP MESSAGE-----<br />
                    Version vl.4.14<br />
                    qqlA/QRrheMz90NG9OQRtc6q2hNCsQUI7kRV7kTrgPCrzKepLwZ3EBcSea8vyQcH
                    f43blJP1VucaTf9jvGPX1qgisOFHBYcZKv1+1I4sRIFcSWpKr3VeCr76bq+vMk1D dwWFasQaXg---tfoS<br />
                    -----END PGP MESSAGE-----
                  </code>
                </div>
                <div class="vx-col w-6/12">
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg" id="input3">
                  </vue-draggable-container>
                </div>
              </div>
            </div>
          </vx-card>
        </div>
      </div>
    </vue-sortable>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import Prism from 'vue-prism-component';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),
      draggingCard: false,

      options: {
        draggable: '.dragBlock',
        dropzone: '.dropzoneStandard',
      },
    };
  },
  methods: {
    startedDrag(x) {
      x.data.source.className += ' currently-dragging-standard';
      this.draggingCard = true;
    },
    stoppedDrag() {
      this.draggingCard = false;
    },
    sort(x) {
      const overContainer = x.data.dragEvent.data.overContainer;
      const sourceContainer = x.data.dragEvent.data.sourceContainer;
      if (overContainer && overContainer.id !== 'wordBank' && overContainer.children) {
        if (overContainer.children.length > 0 && overContainer.id !== sourceContainer.id) {
          //this.drag_error()
          x.cancel();
        }
      }
    },
    markSimulation() {
      let totalScore = 0;

      if (document.getElementById('input0').textContent === 'Document Authenticity') {
        document.getElementById('input0').style.borderColor = '#28C76F';
        document.getElementById('input0').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input0').style.borderColor = '#fd4445';
        document.getElementById('input0').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input1').textContent === 'Hashed Value') {
        document.getElementById('input1').style.borderColor = '#28C76F';
        document.getElementById('input1').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input1').style.borderColor = '#fd4445';
        document.getElementById('input1').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input2').textContent === 'Public Key') {
        document.getElementById('input2').style.borderColor = '#28C76F';
        document.getElementById('input2').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input2').style.borderColor = '#fd4445';
        document.getElementById('input2').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input3').textContent === 'Encrypted String') {
        document.getElementById('input3').style.borderColor = '#28C76F';
        document.getElementById('input3').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input3').style.borderColor = '#fd4445';
        document.getElementById('input3').style.backgroundColor = '#fd4445';
      }

      if (totalScore === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', totalScore);
      }

      this.$emit('simulation_getting_there', totalScore);
    },
  },
  components: {
    draggable,
    Prism,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>
